body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #87DCE1;
    
  }
  .bulk-page {
    display: flex;
    min-height: 100vh;
  }
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #87DCE1;
    border-radius: 10px; /* Rounded corners for main content */
  }
  .bulk-header {
    position: relative;
    top: 15px;
    left: 65px;
    width: 1730px;
    background-color: #ffffff;
    padding: 10px;
    height: 10vh;
    border-radius: 10px;
  }
  .bulk-header h1 {
    position: relative;
    text-align: start;
    bottom: 0;
    left: 60px;
    font-weight: 600;
    font-size: 35px;
    color: #333;
  }
 
 .bulk-search input {
  position: absolute;
  top: 20px;
  left: 700px;
  padding: 10px;
  width: 350px;
  height: 35px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid grey;
}
.step-header {
    position: relative;
    top: 80px;
    left: 400px;
    width: 1060px;
    background-color: #ffffff;
    padding: 10px;
    height: 20vh;
    border-radius: 10px;
  }
.public-bulk{
  position: absolute;
  bottom: 25px;
  left: 1300px;
  font-size: 60px;
  color: black;
}
.notification-bulk{
  position: absolute;
  bottom: 25px;
  left: 1470px;
  font-size: 60px;
  color: black;
}
.profile-bulk{
  position: fixed;
  width: 80px;
  height: 80px;
  top: 50px;
  left: 1730px;
  cursor: pointer;
}
.arrowback-bulk{
  position: absolute;
  bottom: 15px;
  left: -8px;
  font-size: 65px;
  color: black;
}
.file-upload-section{
  position: relative;
  top: 110px;
  margin: 40px auto;
  padding: 20px;
  max-width: 600px;
  background-color: white;
  border-radius: 10px;

}
