/* General Styles */
body {
    font-family: "Arial", sans-serif;
    background-color: #f4f6f9;
    margin: 0;
    padding: 0;
  }
  
  .contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    left: 430px;
    top: 20px;
    width: 380px;
    background-color: #ffffff;
    padding: 15px;
    height: 80vh;
    border-radius: 20px;
  }
  
  .contact-container {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
  }
  
  h1 {
    position: relative;
    bottom: 210px;
    left: 100px;
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    color: #555;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  /* Form Styles */
  .contact-form {
    position: relative;
    display: flex;
    flex-direction: column;
    right: 150px;
    top: 30px;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 15px;
    left: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 150%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;

  }
  
  .form-group input:focus {
    border-color: #0066ff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 102, 255, 0.2);
  }
  
  /* Error Message */
  .error-message {
    color: #ff0000;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  /* Submit Button */
  .submit-btn {
    position: relative;
    background-color: #00b5ad;
    color: #fff;
    left: 80px;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .submit-btn:hover {
    background-color: #1FAAB2;
  }
  
  /* Popup Styles */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .popup-content p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
  }
  
  .popup-close {
    background-color: #0066ff;
    color: #ffffff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-close:hover {
    background-color: #0052cc;
  }
  @media (max-width:430px){
    /* General Styles */
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f6f9;
  margin: 0;
  padding: 0;
}

.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  left: 20px;
  top:150px;
  width: 350px;
  background-color: #ffffff;
  padding: 15px;
  height: 50vh;
  border-radius: 20px;
}



h1 {
  position: relative;
  bottom: 180px;
  left: 100px;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}


/* Form Styles */
.contact-form {
  position: relative;
  display: flex;
  flex-direction: column;
  right: 130px;
  top: 40px;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  left: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.form-group input {
  width: 150%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;

}

.form-group input:focus {
  border-color: #0066ff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 102, 255, 0.2);
}

/* Error Message */
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-bottom: 10px;
}

/* Submit Button */
.submit-btn {
  position: relative;
  background-color: #00b5ad;
  bottom: 30px;
  color: #fff;
  left: 80px;
  padding: 10px 15px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.submit-btn:hover {
  background-color: #1FAAB2;
}

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-content p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.popup-close {
  background-color: #0066ff;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-close:hover {
  background-color: #0052cc;
}
  }