body {
  font-family: Arial, sans-serif;
  background-color: #005959;
  margin: 0;
  padding: 0;
}

.login-container, .register-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

input {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
}

button {
  background-color: #419393;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #005959;
}
