
/* General Styling */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #87DCE1;
    
}
  
  /* Container */
  .login-page {
    position: relative;
    left: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

  }
  
  .login-container {
    position: relative;
    display: flex;

    background: #ffffff;
    border-radius: 10px;
    overflow:visible;
    right: 150px;
    max-width: 80%;
  }
  
  /* Login Form Section */
  .login-form {
    flex: 1;
    padding: 1px;
    text-align: left;
  }
  
  .logo {

    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  p {
    margin: 5px 0 20px;
    color: #666;
  }
  
  form label {
    font-size: 20px;
    margin-bottom: 5px;
    display: block;
    color: #333;
  }
  
  form input {
    width: 80%;
    height: 30px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .form{
    position: relative;
    right:20% ;
    display: flex;
    justify-content:flex-end;
    align-items:start;
    font-size: 12px;
    color: #666;
    
  }

  .form input{
    position:absolute;
    left: 5%;
    bottom: 50%;
 }
.form h4{
    position: relative;
    right:105%;
    bottom: 25px;
    font-size: 13px;
    font-weight: 100;
}
  
  .form a {
    position: relative;
    color: #1FAAB2;
    text-decoration: none;
    right: 4%;
    bottom: 23px;
   }
   
  
  .form a:hover {
    text-decoration: underline;
  }
  
  .login-btn {
    position: relative;
    width: 60%;
    left: 70px;
    top: 50px;
    background: #1FAAB2;
    color: #ffffff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-btn:hover {
    background: #1FAAB2;
  }
  
  /* Create Account Section */
  .create-account {
    position: absolute;
    bottom: 50px;
    left: 180px;
    text-align: center;
    margin-top:10px;
    font-size: 20px;
    color: #666;
  }
  
  .create-account a {
    color: #1FAAB2;
    text-decoration: none;
    font-weight: bold;
  }
  
  .create-account a:hover {
    text-decoration: underline;
  }
  
  /* Illustration Section */
  .login-illustration {
    flex: 1;
    background-color: #1FAAB2;
    border-radius: 10px;
    overflow:visible;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-illustration img {
    width: 100%;
    max-width: 100%;
  }
  .check input{
    position: absolute;
   bottom: -20px;
   left: -110px;
  }
.check{
  position: absolute;
  left: 200px;
  top:10px;
}
.forgot{
  position: relative;
  top: 8px;
  font-size: 20px;
  left: 10px;
}
 