body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #87DCE1;
}

.addcustomer-page {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #87DCE1;
  border-radius: 10px; /* Rounded corners for main content */
}

.addcustomer-header {
  position: relative;
  top: 25px;
  left: 65px;
  width: 1750px;
  background-color: #ffffff;
  padding: 10px;
  height: 90px;
  border-radius: 10px;
}
.arrowback-addcustomer{
  position: absolute;
  bottom: 15px;
  left: -8px;
  font-size: 65px;
  color: black;
}

.addcustomer-header h1 {
  position: relative;
  text-align: start;
  bottom: 0;
  left: 60px;
  font-size: 35px;
  font-weight: 600;
  color: #333;
}


.addcustomer-search input {
  position: absolute;
  top: 15px;
  left: 800px;
  padding: 10px;
  width: 350px;
  height: 35px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid grey;
}

.table-header {
  position: relative;
  top: 47px;
  left: 65px;
  width: 1060px;
  background-color: #ffffff;
  padding: 10px;
  height: 60vh;
  border-radius: 10px;
}


.public-addcustomer{
  position: absolute;
  bottom: 25px;
  left: 1300px;
  font-size: 60px;
  color: black;
}
.notification-addcustomer{
  position: absolute;
  bottom: 25px;
  left: 1470px;
  font-size: 60px;
  color: black;
}
.profile-addcustomer{
  position: fixed;
  width: 80px;
  height: 80px;
  top: 60px;
  left: 1730px;
  cursor: pointer;
}
.addcustomer-table{
  position: absolute;
  margin: 50px;
  width: 1730px;
  height: 690px;
  left: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  top: 180px;
  overflow-y:auto ;
  max-height: 600px;
}