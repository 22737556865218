body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;  
  position: relative;
  background-color: #f4f7fc; /* Light background for the whole page */
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  position: relative;
  top: 10px;
  width: 280px; /* Adjusted width */
  height: 850px;
  left: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  cursor:pointer;
}

.sidebar .logo {
  font-size: 2.2rem; /* Slightly larger logo */
  font-weight: bold;
  color: #00b5ad;
  margin-bottom: 30px;
  text-align: center; /* Centered the logo */
}

.sidebar nav ul {
  position: relative;
  right: 60px;
  list-style: none;

}

.sidebar nav ul li {
  padding: 16px 30px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer ;
  width: 95%;
  color: #333;
 
}

.sidebar nav ul li:hover {
  color: #ffffff;
  background-color: #00b5ad; /* Hover effect */
  border-top-right-radius: 35px;
  border-bottom-right-radius:35px ;
  padding-left: 20px; /* Indentation on hover */
  transition: all 1.20s ease;
}

.sidebar nav ul li.active {
  color: #ffffff;
  background-color: #00b5ad; /* Active page background color */
  border-top-right-radius: 35px;
border-bottom-right-radius:35px ;
  font-weight: bold;
  padding-right: 3px 5px; /* Active page indentation */
  transition: all 0.5s ease-in-out;
}

.sidebar nav ul li.active:hover {
  background-color: #00b5ad; /* Keep the active color on hover */
  
}
.icon{
  position: relative;
  right: 10px;
}

 .logout {
  position: relative;
  top: 205px;
  padding: 15px;
  height: 40px;
  cursor: pointer;
  font-weight: bold;
  color: #000000;
  text-align: center;
  background-color: #1fabb264;
  border-radius: 8px;
  transition: all 0.3s ease;
}
.logout img{
  position: relative;
  top: 3px;
}
.icon1{
  position: relative;
  left: 80px;
  top: -5px;
  font-size: 23px;
}
.logout span{
  position: relative;
  top: -9px;
  right: 20px;
}
.sidebar .logout:hover {
  background-color: rgba(255, 0, 0, 0.514);
  color: white; /* Change text color when hovered */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #87DCE1;
  border-radius: 10px; /* Rounded corners for main content */
}

.dashboard-header {
  position: relative;
  bottom: 28px;
  left: 2px;
  width:80%;
  background-color: #ffffff;
  padding: 10px;
  height: 10vh;
  border-radius: 10px;
}

.dashboard-header h1 {
  position: relative;
  text-align: start;
  font-size: 35px;
  bottom: 0;
  left: 10px;
  font-weight: 600;
  color: #333;
}

.profile{
  position: fixed;
  width: 70px;
  height: 70px;
  top: 35px;
  left: 1780px;
  cursor: pointer;
}


.functional1{
  position: relative;
  bottom: 15px;
  display: flex;
 cursor: pointer;
  gap: 20px;
  right: -5px;
  margin-bottom: 20px;
}
.btn1{
  position: relative;
 width: 265px;
 
 height: 265px;

}
.btn1 img{
  position: relative;
  right: 20px;
  bottom: 10px;
  
  width: 265px;
  height: 265px;
}

.stats {
  position: relative;
  bottom: 265px;
  left: 300px;
  width: 1000px;
  height: 19%;
  display: flex;
  gap: 35px;
  margin-bottom: 5px;
}

.stats .stat {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
}

.stat p {
  color: black;
  font-weight: bold;
}

.charts {
  position: relative;
  bottom: 170px;
  left: 20px;
  display: flex;
  gap: 20px;
}

.chart {
  flex: 1;
}

.bar-sales,
.bar-purchase {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.chart h3 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
  bottom: 25px;
  left: 10px;
}
.statdown{
  position: relative;
  bottom: 250px;
  left: 304px;
  height: 38px;
  width: 1000px;
  display: flex;
  gap: 35px;
  margin-bottom: 5px;
  cursor: pointer;
 
}
.statdown .down {
  background-color: #ffffff;
  padding: 1px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
}
.down h3{
  position: relative;
  bottom: 10px;
}
.charts  {
  position: relative;
  left:5px;
  background-color: #87DCE1;
  bottom: 180px;
  width: 70%;
}
.chart1 {
  position: relative;
  bottom: 25px;
  background-color: white;
  left: 3px;
  
}
.chart2{
  position: relative;
  bottom: 25px;
  left: 2.5px;
  background-color: white;
}
.bar-sales h3{
  position: relative;
  bottom: 20px;
  left:-130px;
  color: black;
  font-size: 20px;
  font-weight: 500;
}
.bar-purchase h3{
  position: relative;
  left: -140px;
  color: black;
  font-weight: 500;
  font-size: 20px;
}
  
  .bar-purchase{
    position: relative;
    bottom: -5px;
    right: 130px;
  }
/* Add the dark background effect */
.dark-background {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  position: relative;
  z-index: 10; /* Ensure the background covers the content */
}

/* Profile Overlay Styles */
.profile-overlay {

  position: fixed;
  width:100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1000000; /* Ensure overlay is above the content */
}

.view-profile {
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 250px;
  height: 290px;
  bottom: 155px;
  left: 780px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.view-profile b{
  position: relative;
  top: 19px;
  right: 50px;
}
.view-profile p{
  position: relative;
  color: #000000;
  display:flex;
  top: 20px;
  left: 35px;
}
.view-profile img{
  position: relative;
  width: 100px;
  height: 90px;
  left: 30px;
}
.view-profile hr{
  position: relative;
  top: 10px;
}


/* Profile Icon */
.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: iconter;
}

.suggestion-item {
  position: relative;
  top: 8px;
  left: 490px;
  width: 98px;
  background-color: #ffffff;
  padding: 10px;
  height: 1vh;
  border-radius: 10px;
  z-index: 100000;
}
.dp-heading{
  position: relative;
  display: flex;
}
.dp-heading z{
  position: relative;
  font-size: 13px;
  top: -2px;
  left: 45px;
  cursor: pointer;
}
.dp-heading c{
  position: relative;
  font-size: 13px;
  top: 0px;
  left: 5px;
  
}
.dp-heading d{
  position: relative;
  font-size: 13px;
  top: 33px;
  right: 75px;
  cursor: pointer;

}
.dp-heading e{
  position: relative;
  font-size: 13px;
  top: 68px;
  right: 158px;
  cursor: pointer;
}
.dp-heading f{
  position: relative;
  font-size: 13px;
  top: -16px;
  right: -45px;
  cursor: pointer;
}
.shortcuts-overlay{
  position: relative;
  bottom: 13px;
  left: 650px;
  width:25%;
  background-color: #464242f9;
  padding: 10px;
  height: 210px;
  border-radius: 10px;
  color: white;
  z-index: 100000;
}
.overlay-content h2{
  position: relative;
  color: white;
  font-size: medium;
  font-weight: 900;
}
.overlay-content ul{
  position: relative;
  color: white;
  font-size: 11px;
  font-weight: 100;
}
.shortcut{
  position: relative;
  bottom: 50px;
  font-size: 40px;
  left: 530px;
  cursor: pointer;
}
.cancle-shortcut{
  position: relative;
  color: red;
  bottom: 195px;
  font-size: 20px;
  cursor: pointer;
  left: 380px;
}
.public{
  position: absolute;
  bottom: 25px;
  left: 1100px;
  font-size: 60px;
  color: black;
}
.notification{
  position: absolute;
  bottom: 25px;
  left: 1250px;
  font-size: 60px;
  color: black;
}
@media screen and (min-width: 1440px) and (max-width: 1440px) 
  and (min-height: 860px) and (max-height: 860px){
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100vh;  
      position: relative;
      background-color: #f4f7fc; /* Light background for the whole page */
    }
    
    .dashboard-container {
      display: flex;
      min-height: 100vh;
    }
    
    .sidebar {
      position: relative;
      top: 12px;
      width: 180px; /* Adjusted width */
      height: 800px;
      left: 20px;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 20px;
      cursor:pointer;
    }
    .logout {
      position: relative;
      top: 240px;
      padding: 5px;
      height: 40px;
      cursor: pointer;
      font-weight: bold;
      color: #000000;
      text-align: center;
      background-color: #1fabb264;
      border-radius: 8px;
      transition: all 0.3s ease;
    }
    
    .dashboard-header {
      position: relative;
      bottom: 28px;
      left: 10px;
      width:80%;
      background-color: #ffffff;
      padding: 10px;
      height: 10vh;
      border-radius: 10px;
    }
    .dashboard-header h1 {
      position: relative;
      text-align: start;
      font-size: 25px;
      top: 10px;
      left: 10px;
      font-weight: 600;
      color: #333;
    }
    
    .profile{
      position: fixed;
      width: 65px;
      height: 65px;
      top: 33px;
      left: 1310px;
      cursor: pointer;
    }
    .shortcut{
      position: absolute;
      bottom: 35px;
      font-size: 40px;
      left: 430px;
      cursor: pointer;
    }
    .cancle-shortcut{
      position: relative;
      color: red;
      bottom: 195px;
      font-size: 20px;
      cursor: pointer;
      left: 260px;
    }
    .shortcuts-overlay{
      position: relative;
      bottom: -10px;
      left: 435px;
      width:25%;
      background-color: #464242f9;
      padding: 10px;
      height: 210px;
      border-radius: 10px;
      color: white;
      z-index: 100000;
    }
    .public{
      position: absolute;
      bottom: 20px;
      left: 930px;
      font-size: 60px;
      color: black;
    }
    .notification{
      position: absolute;
      bottom: 20px;
      left: 840px;
      font-size: 60px;
      color: black;
    }
  }