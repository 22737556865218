body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #87DCE1;
  
}
.reports-page {
  display: flex;
  min-height: 100vh;
}
.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #87DCE1;
  border-radius: 10px; /* Rounded corners for main content */
}
.reports-header {
  position: relative;
  top: 15px;
  left: 65px;
  width: 1060px;
  background-color: #ffffff;
  padding: 10px;
  height: 10vh;
  border-radius: 10px;
}
.reports-header h1 {
  position: relative;
  text-align: start;
  bottom: 0;
  left: 60px;
  font-weight: 600;
  color: #333;
}
.back{
position: relative;
bottom: 90%;
color: black;
}
.header-actions input {
position: absolute;
top: 10px;
left: 500px;
padding: 10px;
width: 200px;
font-size: 14px;
border-radius: 10px;
border: 1px solid grey;
}




  