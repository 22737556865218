body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #87DCE1;
    
  }
  .creditnote-page {
    display: flex;
    min-height: 100vh;
  }
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #87DCE1;
    border-radius: 10px; /* Rounded corners for main content */
  }
  .creditnote-header {
    position: relative;
    top: 15px;
    left: 65px;
    width: 1750px;
    background-color: #ffffff;
    padding: 10px;
    height: 90px;
    border-radius: 10px;
  }
  .creditnote-header h1 {
    position: relative;
    text-align: start;
    bottom: 0;
    left: 60px;
    font-size: 35px;
    font-weight: 600;
    color: #333;
  }
  .back{
  position: relative;
  bottom: 90%;
  color: black;
  }

  .creditnote-search input {
    position: absolute;
    top: 20px;
    left: 700px;
    padding: 10px;
    height:35px;
    width: 350px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid grey;
  }
.arrowback-creditnote{
  position: absolute;
  bottom: 15px;
  left: -8px;
  font-size: 65px;
  color: black;
}

.public-creditnote{
  position: absolute;
  bottom: 25px;
  left: 1300px;
  font-size: 60px;
  color: black;
}
.notification-creditnote{
  position: absolute;
  bottom: 25px;
  left: 1470px;
  font-size: 60px;
  color: black;
}
.profile-creditnote{
  position: fixed;
  width: 80px;
  height: 80px;
  top: 50px;
  left: 1730px;
  cursor: pointer;
}
/*  Table Styles */
.creditnote-list {
  position: relative;
  width: 94%;
  left: 70px;
  bottom: -82px;
  margin-top: 30px;
  border-radius: 8px;
overflow-y: auto;
max-height: 635px;
border:1px solid #ccc;
background-color: white;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.creditnote-table {
  position: relative;

  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.creditnote-table th,
.creditnote-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.creditnote-table th {
  background-color: #00b5ad;
  color: white;
}

.creditnote-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.creditnote-table tr:hover {
  background-color: #f1f1f1;
}

  
  
  
    