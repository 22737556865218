body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #87DCE1;
    
  }
  .additems-page {
    display: flex;
    min-height: 100vh;
  }
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #87DCE1;
    border-radius: 10px; /* Rounded corners for main content */
  }
  .additems-header {
    position: relative;
    top: 15px;
    left: 65px;
    width: 1750px;
    background-color: #ffffff;
    padding: 10px;
    height: 90px;
    border-radius: 10px;
  }
  .additems-header h1 {
    position: relative;
    text-align: start;
    bottom: 0;
    left: 60px;
    font-size: 35px;
    font-weight: 600;
    color: #333;
  }
  .back{
  position: relative;
  bottom: 90%;
  color: black;
  }
  .additems-search input {
    position: absolute;
    top: 20px;
    left: 700px;
    padding: 10px;
    height:35px;
    width: 350px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid grey;
  }
  .arrowback-additems{
    position: absolute;
    bottom: 15px;
    left: -8px;
    font-size: 65px;
    color: black;
  }
  
  .public-additems{
    position: absolute;
    bottom: 25px;
    left: 1300px;
    font-size: 60px;
    color: black;
  }
  .notification-additems{
    position: absolute;
    bottom: 25px;
    left: 1470px;
    font-size: 60px;
    color: black;
  }
  .profile-additems{
    position: fixed;
    width: 80px;
    height: 80px;
    top: 50px;
    left: 1730px;
    cursor: pointer;
  }
  /*  Table Styles */
  .additems-list {
    position: relative;
    width: 94%;
    left: 70px;
    bottom: -82px;
    margin-top: 30px;
    border-radius: 8px;
  overflow-y: auto;
  max-height: 635px;
  border:1px solid #ccc;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .additems-table {
    position: relative;
  
    width: 100%;
    min-width: 1200px;
    border-collapse: collapse;
  }
  
  .additems-table th,
  .additems-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .additems-table th {
    background-color: #00b5ad;
    color: white;
  }
  
  .additems-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .additems-table tr:hover {
    background-color: #f1f1f1;
  }
/* NewItemForm.css */
.form-container {
    position: relative;
    background-color: white;
    font-family: Arial, sans-serif;
    width: 92%;
    height: 45%;
    top: 5%;
    left: 10px;
    margin: auto;
    padding: 20px;
  }
  
  h2 {
    text-align: left;
  }
  

  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .toggle {
    position: relative;
    bottom:610px;
    left: 1700px;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
  }
  
  .toggle.on {
    background-color: green;
  }
  
  
  .toggle.off {
    background-color: red;
  }
  
  .toggle .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-style:groove;
    background-color: #ffffff;
    transition: transform 0.5s;
    transform: translateX(0);
  }
  
  .toggle.on .circle {
    transform: translateX(25px);
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .save-button {
    position: relative;
   bottom: 90px;
   left: 90px;
    background-color: #00bfa5;
    color: white;
    padding: 10px 20px;
    width: 10%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .multiple{
    position: absolute;
    bottom: 210px;
    left: 110px;
    font-size: 20px;
  background-color: transparent;
  color: black;
 }
 .multiple:hover{
    background-color: transparent;
 }
  .cancel-button {
    position: relative;
    bottom: 90px;
    right: 1350px;
    width: 10%;
    background-color: white;
    color: #333;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  .cancel-button:hover{
    background-color: transparent;
  }
  .itemname{
    position: relative;
    top:10px;
  }
  .itemname label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .itemname input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .uom{
    position: relative;
    top:10px;
  }
  .uom label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .uom input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .mrp{
    position: relative;
    top:10px;
  }
  .mrp label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .mrp input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .brand{
    position: relative;
    top:10px;
  }
  .brand label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .brand input{
    position: relative;
    height: 30px;
    width: 25%;
  }

.sku{
  position: relative;
  bottom:355px;
  left:600px;
}
.sku label{
  position: relative;
  font-family:'Arial Narrow';
  top: 10px;
  font-weight: 100;
}
.sku input{
  position: relative;
  height: 30px;
  width: 25%;
}
.hsn{
    position: relative;
    bottom:355px;
    left:600px;
  }
  .hsn label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .hsn input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .sellprice-BF{
    position: relative;
    bottom:355px;
    left:600px;
  }
  .sellprice-BF label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .sellprice-BF input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .barcode{
    position: relative;
    bottom:355px;
    left:600px;
  }
  .barcode label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .barcode input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .type{
    position: relative;
    bottom:720px;
    left:1200px;
  }
  .type label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .type input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .taxrate{
    position: relative;
    bottom:720px;
    left:1200px;
  }
  .taxrate label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .taxrate input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .sellprice-AF{
    position: relative;
    bottom:720px;
    left:1200px;
  }
  .sellprice-AF label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .sellprice-AF input{
    position: relative;
    height: 30px;
    width: 25%;
  }
  .category{
    position: relative;
    bottom:720px;
    left:1200px;
  }
  .category label{
    position: relative;
    font-family:'Arial Narrow';
    top: 10px;
    font-weight: 100;
  }
  .category input{
    position: relative;
    height: 30px;
    width: 25%;
  }
 /* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background-color: #fff; /* White background for content */
  padding: 20px;
  border-radius: 8px;
  height: 500px;
  width: 1200px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.back-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  color: #333;
}

.overlay-content h2 {
  margin-bottom: 20px;
}

.overlay-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overlay-content form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.overlay-content form button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay-content form button:hover {
  background-color: #0056b3;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin: auto;
}

.row {
  display: flex;
  gap: 50px;
}

.input-group {
  display: flex;
  flex-direction:column;
  width: 100%;
  height: 100px;
}
.label {
  margin-bottom: 5px;
  font-weight: bold;

}

.input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150%;
}
.submit-button{
  position: relative;
  width: 30%;
  border-radius: 5px;
  height: 50px;
  font-size: larger;
}
.row input{
  position: relative;
  font-size: 20px;
}
.row label{
  position: relative;
 font-weight: 500;
}