.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;  
  position: relative;
  background-color: #f4f7fc; /* Light background for the whole page */
}

.gst-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sidebar-menu li {
    list-style: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .sidebar-menu li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .sidebar-menu li.active {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  h1{
    color: black;
    font-weight: 900;
  }
  p{
    color: white;
    font-weight:700;

  }