body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #87DCE1;
  position: fixed;
}

.newinvoice-page {
  display: flex;
  min-height: 100vh;
}

.arrowback-newinvoice {
  position: relative;
  top: 23px;
  left: 8px;
  font-size: 65px;
  background-color: white;
  cursor: pointer;
  color: black;
  border-radius: 10px;
}


.header1 input {
  position: relative;
  width: 80%;
  left: 30px;
  height: 15px;
}

.receipt-newinvoice {
  position: absolute;
  top: 53px;
  font-size: 25px;
  right: 390px;
}

.header1 {
  position: relative;
  background-color: white;
  width: 450px;
  top: 20px;
  border-radius: 10px;
  left: 20px;
  height: 170px;
}

.header1 b {
  position: absolute;
  bottom: 95px;
  font-size: 14px;
  left: 65px;
}

.vertical-line1 {
  position: absolute;
  width: 2px;
  height: 60px;
  background-color: black;
  border: none;
  bottom: 50px;
  left: 210px;
}

.header1 c {
  position: absolute;
  bottom: 95px;
  font-size: 14px;
  left: 250px;
  color: black;
  font-weight: 550;
}

.header1 u {
  position: absolute;
  bottom: 65px;
  font-size: 11px;
  right: 330px;
  color: black;
  font-weight: 550;
}

.header2 {
  padding: 5px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 1020px;
  bottom: 150px;
  border-radius: 10px;

  left: 487px;
  height: 160px;
}

.invoicedate1 {
  position: relative;
  font-family: Arial;
  font-size: 13px;
  top: 25px;
  left: 2px;
}

.date {
  position: relative;
  width: 16%;
  top: 15px;
  height: 13px;
}

.duedate {
  position: relative;
  bottom: 70px;
  width: 16%;
  height: 13px;
  left: 220px;
}

.duedate1 {
  position: relative;
  font-size: 13px;
  bottom: 60px;
  left: 220px;
}

.podate1 {
  position: relative;
  font-size: 13px;
  bottom: 85px;
  left: 3px;

}

.podate {
  position: relative;
  width: 16%;
  bottom: 75px;
  height: 13px;
}

.deliverydate1 {
  position: relative;
  font-size: 13px;
  bottom: 230px;
  left: 440px;
}

.deliverydate {
  position: relative;
  bottom: 240px;
  width: 16%;
  left: 440px;
  height: 13px;
}

.transportdetails1 {
  position: relative;
  font-size: 13px;
  bottom: 255px;
  left: 225px;
}

.transportdetails {
  position: relative;
  bottom: 265px;
  width: 16%;
  left: 225px;
  height: 13px;
}

.placeofsupply1 {
  position: relative;
  font-size: 13px;
  bottom: 340px;
  left: 440px;
}

.placeofsupply {
  position: relative;
  bottom: 350px;
  width: 16%;
  left: 440px;
  height: 13px;
}

.invoicenumber1 {
  position: relative;
  font-size: 13px;
  bottom: 425px;
  left: 670px;
}

.invoicenumber {
  position: relative;
  bottom: 435px;
  width: 30%;
  left: 670px;
  height: 13px;
}

.pob1 {
  position: relative;
  font-size: 13px;
  bottom: 570px;
  left: 670px;
}

.pob {
  position: relative;
  bottom: 580px;
  width: 30%;
  left: 670px;
  height: 13px;
}

.radio {
  position: relative;
  bottom: 23px;
  width: 30px;
  left: 20px;
 
}

.radio2 {
  position: relative;
  bottom: 18px;
}

.radio2 c {
  position: relative;
  top: -95px;
}

.radio1 c {
  position: relative;
  top: 10px;
}
/* Item Table */
/* Table Container */
.invoice-table {
  position: fixed;
  top: 200px;
  left: 85px;
  border-radius: 10px;
  margin-top: 0px;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: auto; /* Enable vertical scroll */
  height: 38%; /* Set fixed height for the table */
  width: 78%; /* Set width for the table */
}

/* Table Style */
.invoice-table table {
  width: 100%; /* Ensure table takes full width of the container */
  border-collapse: separate;
  background-color: #fff;
}

/* Table Header Style */
.invoice-table th {
  text-align: center;
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 5px; /* Added padding for headers */
}

/* Table Body Style */
.invoice-table td {
  position: relative;
  justify-items: left;
  border-bottom: 1px solid #f1f1f1;
  color: #555;
  font-size: 14px;
  padding: 0px 15px; /* Added padding for cells */
}

.invoice-table input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #333;
  padding: 5px; /* Added padding inside input fields */
}

.invoice-table input:focus {
  outline: none;
  border-color: #2d6a4f;
  box-shadow: 0 0 5px rgba(45, 106, 79, 0.2);
}

/* Hover effect on table rows */
.invoice-table tr:hover {
  background-color: #f9f9f9;
}

/* Add Item Button Style */
.invoice-table .MuiButton-root {
  background-color: #2d6a4f;
  color: white;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.invoice-table .MuiButton-root:hover {
  background-color: #1b3c2f;
}

/* Responsive Table for smaller screens */
@media screen and (max-width: 768px) {
  .invoice-table th,
  .invoice-table td {
    font-size: 12px;
    padding: 8px; /* Consistent padding for small screens */
  }

  .invoice-table input {
    font-size: 12px;
  }

  .invoice-table .MuiButton-root {
    font-size: 12px;
    padding: 8px 15px;
  }
}

/* Totals Section */
.totals {
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 50%;
  top: 220px;
  left: 1275px;
  height: 300px;
  margin-top: 0px;
  padding: 10px;
  font-size: 40px;
  font-weight: bold;
  border-radius: 10px;
  z-index: 10000000000;
}

.totals p {
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin: 5px 0;
}

.totals p span {
  font-size: 25px;
  font-weight: 500;
  float: right;
}

.vertical-line2 {
  position: absolute;
  width: 1px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.401);
  border: none;
  bottom: -10px;
  left: 270px;
}

.payment {
  position: relative;
  font-size: 20px;
}

.buttons1 b {
  position: relative;
  font-size: 20px;
  font-weight: 100;
}

.btn-down b {
  position: relative;
  font-size: 20px;
  font-weight: 100;
}