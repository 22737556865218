body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #87DCE1;
    position: fixed;
  }
  .newdelliverychallan-page {
    display: flex;
    min-height: 100vh;
  }
  .arrowback-newdelliverychallan{
    position: relative;
    top : 23px;
    left: 8px;
    font-size: 65px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 10px;
  }


  .newdelliverychallan-header1 input{
    position: relative;
    width: 80%;
    left: 30px;
    height: 15px;
  }
  .receipt-newdelliverychallan{
    position: absolute;
    top: 53px;
    font-size: 25px;
    right:390px;
  }
  .newdelliverychallan-header1{
    position: relative;
    background-color: white;
    width: 450px;
    top: 20px;
    border-radius: 10px;
    left: 20px;
    height: 170px;
  }
  .newdelliverychallan-header1 b{
    position: absolute;
    bottom:95px;
   font-size: 14px;
   left: 65px;
}
.vertical-line1 {
    position: absolute;
    width: 2px; 
    height: 60px; 
    background-color: black; 
    border: none; 
    bottom: 50px;
    left: 210px;
  }
  .newdelliverychallan-header1 c{
    position: absolute;
    bottom: 95px;
   font-size: 14px;
   left: 250px;
   color: black;
    font-weight:550;
}
.newdelliverychallan-header1 u{
    position: absolute;
    bottom: 65px;
   font-size: 11px;
   right: 330px;
   color: black;       
    font-weight:550;
}

.newdelliverychallan-header2{
    padding: 5px;
    position: relative;
    background-color: rgb(255, 255, 255);
    width: 1020px;
    bottom: 150px;
    border-radius: 10px;
    
    left: 487px;
    height: 160px;
}

     .ewaybill1 {
        position: relative;
        font-size: 13px;
        top: 5px;
        left: 730px;
    }
    .ewaybill{
        position: relative;
        top: 0px;
        width: 25%;
        left:725px;
        height: 25px;
     }
     .placeofsupply-delliverychallan1 {
        position: relative;
        font-size: 13px;
        top: -15px;
        left: 520px;
    }
    .placeofsupply-delliverychallan{
        position: relative;
        top: -25px;
        width: 17%;
        left: 515px;
        height: 25px;
     }
     .notes1 {
        position: relative;
        font-size: 13px;
        bottom: 190px;
        left: 285px;
    }
    .notes{
        position: relative;
        bottom: 195px;
        width: 40%;
        left: 280px;
        height:25px;
     }
     .delliverychallannumber1 {
        position: relative;
        font-size: 13px;
        bottom: 208px;
        left: 80px;
    }
    .delliverychallannumber{
        position: relative;
        bottom: 220px;
        width: 40%;
        left: 70px;
        height: 25px;
     }
 
     .challandate1   {
        position: absolute;
        font-family:  Arial;
        font-size: 15px;
        top: 10px;
        left: 80px;
    }
    .challandate{
    position: absolute;
    width: 16.5%;
    top: 25px;
    height: 25px;
    left: 75px;
    }
 
  .radio{
    position:absolute;
  
  }
.radio1 c{
  position: absolute;
  left: 30px;
  bottom: 90px;
}
.radioinput{
  position: relative;
  bottom:3px;
  
}

.radio2 c{ 
  position: relative;
  bottom: 85px;
  left: 120px;
}
.radioinput1{
  position: relative;
  top: -50px;
  left: 90px;
}
  
  /* Totals Section */
  .totals {
    position: relative;
    background-color:rgb(255, 255, 255);
    width: 50%;
    top: 220px;
    left: 1275px;
    height: 300px;
    margin-top: 0px;
    padding: 10px;
    font-size: 40px;
    font-weight: bold;
    border-radius: 10px;
    z-index: 10000000000;
  }
  
  .totals p {
    position: relative;
    color: rgb(0, 0, 0);
    font-size: 20px;
    margin: 5px 0;
  }
  
  .totals p span {
    font-size: 25px;
    font-weight: 500;
    float: right;
  }
  .vertical-line2 {
    position: absolute;
    width: 1px; 
    height: 300px; 
    background-color: rgba(0, 0, 0, 0.401); 
    border: none; 
    bottom: -10px;
    left: 270px;
  }
.payment{
  position: relative;
  font-size: 20px;
}
.buttons1 b{
  position: relative;
  font-size: 20px;
  font-weight: 100;
}
.btn-down b{
  position: relative;
  font-size: 20px;
  font-weight: 100;
}