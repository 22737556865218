body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #87DCE1;
}

.customer-page {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #87DCE1;
  border-radius: 10px; /* Rounded corners for main content */
}

.customer {
  position: relative;
  top: 15px;
  left: 65px;
  width: 1750px;
  background-color: #ffffff;
  padding: 10px;
  height: 90px;
  border-radius: 10px;
}
.arrowback-customer{
  position: absolute;
  bottom: 15px;
  left: -8px;
  font-size: 65px;
  color: black;
}

.customer h1 {
  position: relative;
  text-align: start;
  bottom: 0;
  left: 60px;
  font-size: 35px;
  font-weight: 600;
  color: #333;
}

.back {
  position: relative;
  bottom: 90%;
  color: black;
}

.header-search input {
  position: absolute;
  top: 20px;
  left: 700px;
  padding: 10px;
  height:35px;
  width: 350px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid grey;
}

.table-header {
  position: relative;
  top: 47px;
  left: 65px;
  width: 1060px;
  background-color: #ffffff;
  padding: 10px;
  height: 60vh;
  border-radius: 10px;
}

/* New Table Styles */
.customer-list {
  position: relative;
  width: 94%;
  left: 70px;
  bottom: -82px;
  margin-top: 30px;
  border-radius: 8px;
overflow-y: auto;
max-height: 635px;
border:1px solid #ccc;
background-color: white;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customer-table {
  position: relative;

  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.customer-table th,
.customer-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.customer-table th {
  background-color: #00b5ad;
  color: white;
}

.customer-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.customer-table tr:hover {
  background-color: #f1f1f1;
}

.customer-item {
  border-bottom: 1px solid #eee;
  padding: 12px 0;
}

.customer-item:last-child {
  border-bottom: none;
}

.customer-item p {
  margin: 8px 0;
  color: #555;
}

.customer-item b {
  font-weight: bold;
}
.edit-button:hover{
  color: skyblue;
}
.delete-button:hover{
  color: red;
}

.public-customer{
  position: absolute;
  bottom: 25px;
  left: 1300px;
  font-size: 60px;
  color: black;
}
.notification-customer{
  position: absolute;
  bottom: 25px;
  left: 1470px;
  font-size: 60px;
  color: black;
}
.profile-customer{
  position: fixed;
  width: 80px;
  height: 80px;
  top: 50px;
  left: 1730px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 768px) {
  .header-actions input {
      position: relative;
      left: 0;
      width: 100%;
  }

  .customer-header h1 {
      font-size: 24px;
  }

  .customer-list {
      padding: 10px;
      overflow-x: auto;
      white-space: nowrap;
  }

  .button-section {
      flex-direction: column;
      align-items: center;
  }
}
