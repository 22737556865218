.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;  
  position: relative;
  background-color: #f4f7fc; /* Light background for the whole page */
}
.items-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sidebar-menu li {
    list-style: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .sidebar-menu li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .sidebar-menu li.active {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  h1{
    color: black;
    font-weight: 900;
  }
  p{
    color: white;
    font-weight:700;

  }
  
.items-container {
  display: flex;
  min-height: 100vh;
}
.items-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #87DCE1;
  border-radius: 10px; /* Rounded corners for main content */
}

.items-header {
  position: relative;
  bottom: 28px;
  left: 20px;
  width: 1480px;
  background-color: #ffffff;
  padding: 10px;
  height: 10vh;
  border-radius: 10px;
}

.items-header h1 {
  position: relative;
  text-align: start;
  bottom: 0;
  left: 10px;
  font-size: 35px;
  font-weight: 600;
  color: #333;
}
.items-actions input {
  position: absolute;
  top: 20px;
  left: 600px;
  padding: 10px;
  width: 350px;
  height: 30px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
.functional-btn-items1{
  position: absolute;
  top: 140px;
  display: flex;
  cursor: pointer;
  height: 23%;
  width:23%;
  gap: 36px;
  right: 1435px;
  margin-bottom: 20px;
}


.btn{
  position: relative;
 width: 150px;
 height: 150px;
}
.btn img{
  position: relative;
  right: 20px;
  bottom: 10px;
  width: 150px;
  height: 150px;
}



.public{
  position: absolute;
  bottom: 25px;
  left: 1100px;
  font-size: 60px;
  color: black;
}
.notification{
  position: absolute;
  bottom: 25px;
  left: 1250px;
  font-size: 60px;
  color: black;
}
.profile{
  position: fixed;
  width: 70px;
  height: 70px;
  top: 35px;
  left: 1780px;
  cursor: pointer;
}
